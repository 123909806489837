.pg-viewer-wrapper::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.pg-viewer-wrapper {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

.pdf-canvas {
    background-color: rgb(239,239,239);
}

.pdf-viewer .pdf-canvas canvas, .document-container {
    padding-left: 0;
    padding-right: 0;
    margin-left: auto;
    margin-right: auto;
    display: block;
    width: 60%;
    border: 1px solid black;
    margin-top: 16px !important;
    margin-bottom: 32px !important;
}

.file-modal .MuiDialog-container .MuiPaper-root {
    background-color: rgb(239,239,239);
}
